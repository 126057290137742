@import "../../assets/scss/variables";

.topBanner {
  width: 100%;
  height: 388px;
  background-image: url("../../assets/img/about/about-hero-comp.jpg");
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  top: -96px;
  margin-bottom: -92px;
}

.aboutSection {
  padding: 135px 0 0;
  position: relative;
  overflow: hidden;

  .about1Img {
    width: 100%;
    max-width: 700px;
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1;
  }

  .firstDiv {
    background-color: transparent;
    position: relative;
    z-index: 3;

    .eBg {
      width: 100%;
      max-width: 770px;
      position: absolute;
      top: -180px;
      left: 0;
      z-index: -1;
    }

    .aboutTitle {
      margin-left: -6rem;
      margin-bottom: 92px;
    }

    h1:last-of-type {
      max-width: 620px;
      font-size: 60px;
      margin-bottom: 56px;
    }
  }

  .grayDiv {
    background-color: #f5f5f5;
    position: relative;
    z-index: 2;
    padding: 88px 0 0;

    p:first-of-type {
      margin-bottom: 244px;
    }

    p:last-of-type {
      margin-bottom: 92px;
    }

    .aboutSubTitle {
      font-size: 44px;
      color: $gray-2;
      margin-top: 174px;
    }

    .about2Img {
      width: 100%;
      max-width: 528px;
      position: absolute;
      left: 120px;
      bottom: -66px;
      z-index: -1;
    }
  }

  .thirdDiv {
    background-color: transparent;

    p {
      width: 100%;
      max-width: 420px;
      margin: 256px 0;
    }

    .about3Img {
      width: 100%;
      max-width: 528px;
      position: absolute;
      left: 400px;
      top: 50%;
      transform: translateY(-50%);
      z-index: -1;
    }
  }
}

.gallerySection {
  img {
    width: 100%;
  }
}

.buddyUpBanner {
  background-color: #f5f5f5;
  padding: 64px 0 56px;

  p {
    max-width: 528px;
    margin-bottom: 64px;
  }

  .bannerDiv {
    padding: 0 2rem;
    text-align: center;

    img {
      width: 100%;
      max-width: 1312px;
    }
  }
}

.bottomBanner {
  width: 100%;
  height: 202px;
  background-image: url("../../assets/img/about/banner-bottom-comp.jpg");
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;

  .button {
    position: absolute;
    right: 25%;
    top: 50%;
    transform: translateY(-50%);
  }
}

@media screen and (max-width: 1439px) {
  .aboutSection {
    .firstDiv {
      .aboutTitle {
        margin-left: -2rem;
      }
    }
  }
}

@media screen and (max-width: 1199px) {
  .aboutSection {
    .thirdDiv {
      .about3Img {
        left: auto;
        right: 0;
      }
    }
  }

  .bottomBanner {
    background-position: 30% 50%;
    .button {
      right: 15%;
    }
  }
}

@media screen and (max-width: 991px) {
  .aboutSection {
    .about1Img {
      position: static;
      display: block !important;
      margin: auto;
    }

    .firstDiv {
      .aboutTitle {
        margin-left: 0;
        margin-bottom: 56px;
      }

      h1:last-of-type {
        max-width: 620px;
        font-size: 60px;
      }
    }

    .grayDiv {
      top: 0;
      z-index: 2;
      margin-bottom: 0;

      p:first-of-type {
        margin-bottom: 64px;
      }

      p:last-of-type {
        margin-bottom: 64px;
      }

      .aboutSubTitle {
        font-size: 44px;
        color: $gray-2;
        margin-top: 0;
      }

      .about2Img {
        position: static;
        margin-bottom: 2rem;
      }
    }

    .thirdDiv {
      p {
        width: 100%;
        max-width: none;
        margin: 64px 0;
      }

      .about3Img {
        position: static;
        top: auto;
        transform: none;
        margin-bottom: 64px;
      }
    }
  }

  .bottomBanner {
    background-position: 20% 50%;
    .button {
      right: 5%;
    }
  }
}

@media screen and (max-width: 767px) {
  .topBanner {
    top: -163px;
    margin-bottom: -163px;
  }

  .aboutSection {
    padding: 32px 0 0;

    .firstDiv {
      .eBg {
        top: -64px;
      }

      .aboutTitle {
        margin-left: 0;
        margin-bottom: 56px;
      }

      .about1Img {
        position: static;
      }

      h1:last-of-type {
        max-width: none;
        font-size: 44px;
      }
    }

    .grayDiv {
      padding: 32px 0 0;

      .aboutSubTitle {
        margin-bottom: 2rem;
      }

      .about2Img {
        position: static;
        margin-bottom: 2rem;
      }
    }

    .thirdDiv {
      p {
        margin: 32px 0;
      }

      .about3Img {
        margin-bottom: 32px;
      }
    }
  }

  .buddyUpBanner {
    p {
      max-width: none;
      margin-bottom: 32px;
    }

    .bannerDiv {
      padding: 0;
    }
  }

  .bottomBanner {
    background-size: 250%;
    background-position: 16% 0%;
    background-color: $black;

    .button {
      right: auto;
      left: 50%;
      transform: translateX(-50%);
      top: auto;
      bottom: 0;
    }
  }
}

@media screen and (min-width: 580px) and (max-width: 767px) {
  .bottomBanner {
    background-size: 250%;
    background-position: 16% 100%;
    background-color: $black;

    .button {
      right: auto;
      left: 50%;
      transform: translateX(-50%);
      top: auto;
      bottom: 0;
    }
  }
}
