@import "../assets/scss/variables";

.searchDiv {
  position: relative;

  input {
    position: absolute;
    right: -8px;
    border-radius: 80px;
    border: 1px solid #dddddd;
    padding: 4px 32px 4px 16px;
    top: -4px;
    max-width: 200px;

    &:focus,
    &:active {
      outline: 0;
    }
  }

  .searchIcon {
    position: relative;
    z-index: 999;
    cursor: pointer;
    color: $black;
  }

  .searchedDiv {
    position: absolute;
    width: 260px;
    right: -8px;
    top: 32px;
    background: #fff;
    border-radius: 8px;
    max-height: 160px;
    overflow: auto;

    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        padding: 1rem;
        white-space: pre-line;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        cursor: pointer;

        &:hover {
          background-color: #b1bdc2;
        }

        img {
          width: 100%;
          max-width: 32px;
          display: block;
          margin-right: 1rem;
        }

        span {
          display: block;
        }
      }
    }
  }

  &.white {
    .searchIcon {
      color: $white;
    }
  }
}
