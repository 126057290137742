@import "../assets/scss/variables";

.footer {
  background-color: $black;
  color: $gray-3;
  font-size: 16px;
  padding: 4rem 3rem 1.5rem;

  .logo {
    margin-bottom: 2.5rem;
  }

  .footerTitle {
    max-width: 184px;
    margin-bottom: 0;
  }

  .sectionSubtitle {
    color: $white;
    font-size: 22px;
    margin-bottom: 1rem;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .bottomSection {
    margin-top: 94px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    ul {
      li {
        display: inline-block;
        margin-right: 1rem;
        padding-right: 1rem;
        position: relative;

        &:after {
          content: "•";
          position: absolute;
          right: 0;
        }

        &:last-of-type {
          &::after {
            display: none;
          }
        }

        a {
          color: $gray-3;
        }
      }
    }
  }

  .footerLink {
    li {
      cursor: pointer;

      &:hover {
        color: #fff;
      }
    }
  }
}
