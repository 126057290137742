body {
  font-family: "Yellix";
  color: $black;
  overflow-x: hidden;

  * {
    font-family: "Yellix";
  }
}

/* Home Slider 1 */
.slick-slide {
  position: relative;
}

.slick-dots {
  position: absolute;
  bottom: 40px;

  li button:before {
    content: "";
    border: 2px solid $black;
    border-radius: 12px;
    width: 12px;
    height: 12px;
    background-color: transparent;
    opacity: 1;
  }

  li.slick-active button:before {
    opacity: 1;
    background-color: $black;
  }
}

.slick-slider.white-dots {
  li button:before {
    border: 2px solid $white;
  }

  li.slick-active button:before {
    background-color: $white;
  }
}

/* Home Product Slider */
.products-slider {
  border-right: 1px solid #bcbcbc;
  border-left: 1px solid #bcbcbc;

  .slick-current + div {
    border-right: 1px solid #bcbcbc;
    border-left: 1px solid #bcbcbc;
  }
}

// MAIN NAV
header {
  nav {
    a {
      position: relative;

      &.active {
        font-weight: bold;

        &::after {
          position: absolute;
          content: "";
          width: 6px;
          height: 6px;
          border-radius: 6px;
          background-color: $blue;
          left: 50%;
          transform: translateX(-50%);
          bottom: -1rem;
        }
      }
    }
  }
}

/* GENERAL */
.section-title {
  font-size: 44px;
  line-height: 160%;
  color: $black;
  margin-bottom: 40px;
}

.section-subtitle {
  font-size: 20px;
  line-height: 24px;
}

.cursor-pointer {
  cursor: pointer !important;
}

.visibility-hidden {
  visibility: hidden !important;
}

.mb-6 {
  margin-bottom: 4rem;
}

@media (min-width: 1320px) {
  .single-product-page {
    .container,
    .container-sm,
    .container-md,
    .container-lg,
    .container-xl {
      max-width: 1312px;
    }
  }
}
