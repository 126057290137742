@import "../../assets/scss/variables";

.shopWrapper {
  padding: 0 3rem;
}

.topBanner {
  position: relative;
  margin: 0 0 4rem;

  h1 {
    margin-bottom: 1rem;
  }

  img {
    width: 100%;
  }

  .topBannerDiv {
    position: absolute;
    left: 100px;
    top: 50%;
    transform: translateY(-50%);

    h1 {
      color: $white;
      max-width: 400px;
      font-weight: 500;
      font-size: 60px;
      line-height: 100%;
      margin-bottom: 48px;
    }

    img {
      width: auto;
    }
  }
}

.productsSection {
  margin-bottom: 2rem;

  .displayingFormatDiv {
    border: 1px solid #e0e0e0;
    border-radius: 14px;
    padding: 24px 40px;
    max-width: 180px;
    display: flex;
    justify-content: flex-start;
    line-height: 1;

    .div {
      margin-right: 48px;
      cursor: pointer;

      &:last-of-type {
        margin-right: 0;
      }

      span {
        font-size: 24px;
        color: #bdbdbd;
      }

      &.active {
        span {
          color: #4f4f4f;
        }
      }
    }
  }

  .showingInfo {
    font-weight: 500;
    font-size: 16px;
    line-height: 160%;
    text-align: right;
    color: #bdbdbd;
  }
}

.bottomBanner {
  position: relative;
  margin: 0 -3rem 2rem;

  img {
    width: 100%;
  }

  .bottomBannerDiv {
    position: absolute;
    left: 112px;
    top: 50%;
    transform: translateY(-50%);

    h1 {
      color: $white;
      max-width: 400px;
      margin-bottom: 48px;
    }

    img {
      width: auto;
    }
  }
}

.bottomInfoSection {
  padding: 128px 0;

  h1 {
    max-width: 403px;
  }

  p {
    max-width: 528px;
  }
}

@media screen and (max-width: 767px) {
  .shopWrapper {
    padding: 0 1rem;
  }

  .topBanner {
    h1 {
      font-size: 32px;
    }

    .topBannerDiv {
      left: 48px;

      h1 {
        font-size: 32px;
        margin-bottom: 32px;
      }
    }
  }

  .bottomBanner {
    margin: 0 -1rem;

    .bottomBannerDiv {
      left: 2rem;
      h1 {
        max-width: 100%;
        font-size: 40px;
        margin-bottom: 8px;
      }

      img {
        width: auto;
      }
    }
  }

  .bottomInfoSection {
    padding: 64px 0;

    h1 {
      max-width: none;
    }

    p {
      max-width: none;
    }
  }
}

@media screen and (max-width: 499px) {
  .topBanner {
    h1 {
      font-size: 20px;
    }

    img {
      min-height: 140px;
    }

    .topBannerDiv {
      left: 32px;

      h1 {
        font-size: 16px;
        margin-bottom: 32px;
      }

      img {
        min-height: auto;
      }
    }
  }

  .bottomBanner {
    .bottomBannerDiv {
      left: 16px;

      h1 {
        max-width: 400px;
        font-size: 18px;
        margin-bottom: 8px;
      }

      img {
        width: auto;
      }
    }
  }
}

/* Whishlist */
.wishlistTopBanner {
  width: calc(100% + 8rem);
  height: 388px;
  background-image: url("../../assets/img/whislist.jpg");
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  top: -96px;
  margin-bottom: -60px;
  margin-left: -4rem;
  margin-right: -4rem;

  h1 {
    position: absolute;
    left: 5rem;
    top: 50%;
    transform: translateY(-50%);
  }
}

@media screen and (max-width: 767px) {
  .wishlistTopBanner {
    top: -163px;
    margin-bottom: -102px;
  }
}

.topBanner {
}
